import { ErrorMessage, useFormikContext, useField } from 'formik';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { getClass } from '@helpers/formik';
import { DateTime } from 'luxon';

const FieldDatePickerCustom = ({ name, placeholder, errors, openTo = "day" }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#0A1307',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            backgroundColor: '#F5F7F9',
            left: '5px',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            transform: 'translate(14px, -15px) scale(0.75);'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            input: {
              borderRadius: '6px 0 0 6px',
              padding: '0.75rem 1.25rem'
            },
            fieldset: {
              borderWidth: '0px'
            }
          }
        }
      }
    }
  });

  return (
    <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        <ErrorMessage
          name={field.name}
          component="div"
          className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
        />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <div className="desktop:block tablet:block mobile:hidden">
              <DatePicker
                label={placeholder}
                className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
                  errors,
                  field.name
                )}`}
                value={DateTime.fromISO(field.value)}
                onChange={(val) => setFieldValue(field.name, val)}
                disableMaskedInput
                format="yyyy-MM-dd"
              />
            </div>
            <div className="desktop:hidden tablet:hidden mobile:block">
              <MobileDatePicker
                llabel={placeholder}
                className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
                  errors,
                  field.name
                )}`}
                value={DateTime.fromISO(field.value)}
                onChange={(val) => setFieldValue(field.name, val)}
                disableMaskedInput
                format="yyyy-MM-dd"
              />
            </div>
          </LocalizationProvider>
        </ThemeProvider>
        <ErrorMessage
          name={field.name}
          component="div"
          className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
        />
      </div>
    </div>
  );
};

export default FieldDatePickerCustom;
