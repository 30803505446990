import React from 'react';
import { Balance, Banner, TransactionList } from '@components/Dashboard';

const DashboardMain = ({ balance, userFeatures }) => {
  return (
    <main className="main">
      <Balance balance={balance} />
      <Banner userFeatures={userFeatures} />
      <TransactionList />
    </main>
  );
};

export default DashboardMain;
