import React, { useState, useEffect } from 'react';
import { Info } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Inputs/CustomInput';
import Button from '@mui/material/Button';
import { postCreateUpdateExpenseGoals } from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';

const validationSchema = Yup.object().shape({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'Monthly amount must be a valid number with a maximum of 2 decimal.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required')
});

const CushionComponent = ({
  expenseGoals,
  handleBack,
  setExpenseGoals,
  setGetStarted,
  incomeGoals
}) => {
  const [cushion, setCushion] = useState({
    amount: '',
    category: 'cushion',
    fixed: true
  });
  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);

  const cushionInitialValues = (values) => {
    const cushion = values.expense_goals.find((item) => item.category === 'cushion');
    if (cushion) {
      return { ...cushion };
    } else {
      return {
        amount: '',
        category: 'cushion',
        fixed: true
      };
    }
  };

  useEffect(() => {
    if (expenseGoals.expense_goals.length > 0) {
      const resultCushion = cushionInitialValues(expenseGoals);
      setCushion(resultCushion);
    }
  }, [expenseGoals]);

  const handleSubmit = async (values) => {
    if (!handleCushion(values)) {
      setAlert(true);
      return false;
    }
    const expense_goals = [...expenseGoals.expense_goals, values];
    try {
      const response = await postCreateUpdateExpenseGoals({
        data: {
          expense_goal: {
            items: expense_goals
          }
        }
      });
      if (response.status === 200) {
        setExpenseGoals(response.data);
        setGetStarted(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCushion = (cushion) => {
    const expense = expenseGoals.expense_goals.reduce(
      (acc, cur) => Number(acc) + Number(cur.amount),
      0
    );
    const income = incomeGoals.income_goals.reduce(
      (acc, cur) => Number(acc) + Number(cur.amount),
      0
    );
    setValueEnough((expense + Number(cushion.amount)) - income);
    return income >= expense + Number(cushion.amount);
  };

  return (
    <>
      <h3 className="mb-6 text-xl font-semibold">
        It's always nice to have a little left over for savings or emergencies
      </h3>
      {alert && (
        <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
          <p>
            You have not entered enough income to cover your expenses and have this cushion
            remaining. Please review your income and expense or adjust your cushion.
          </p>

          <p className="mt-4 font-bold text-center text-lg">
            Excess in expenses:{' '}
            <CurrencyFormat
              value={valueEnough}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              prefix="$"
            />
          </p>
        </div>
      )}
      <p className="mb-6 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        Planning to have some income left over is a great way to get ahead of your finances
      </p>
      <Formik
        initialValues={cushion}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, isSubmitting }) => (
          <Form>
            <div className="flex justify-start items-center gap-8 w-full">
              <CustomInput name="amount" placeholder="Cushion" icon="fa-dollar-sign" />
            </div>
            <div>
              <Button
                disabled={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mt: 1, mr: 1 }}>
                Finish
              </Button>

              <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CushionComponent;
