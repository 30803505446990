import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TrackSuccessContext } from '@contexts';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { numericFormatter } from 'react-number-format';
import Card from '../Shared/Card';

const PieCenterLabel = ({ children, position, value, text }) => {
  const { width, height, left, top } = useDrawingArea();
  let color = '#444444';
  let fontSize = 14;
  if (!text) {
    color = !text && value && value < 0 ? 'red' : 'green';
    fontSize = 24;
  }
  const StyledText = styled('text')(({ theme }) => ({
    fill: color,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: fontSize,
    fontWeight: 'bold'
  }));
  return (
    <StyledText x={left + width / 2} y={top * 13 + (height * position) / 2}>
      {children}
    </StyledText>
  );
};

const EquationComponent = () => {
  const { incomeGoals, trusteePayment, expenseItems } = useContext(TrackSuccessContext);
  const [data, setData] = useState();

  const totalIncomeGoalsForMonth = incomeGoals.reduce(
    (acc, cur) => Number(acc) + Number(cur.amount),
    0
  );

  const formatNumber = (value) => {
    return numericFormatter(value + '', {
      prefix: '$',
      decimalScale: 2,
      fixedDecimalScale: true,
      isNumericString: true,
      thousandSeparator: ','
    });
  };

  const totalExpenseItemsForMonth = useMemo(() => {
    return expenseItems.reduce((acc, cur) => {
      if (cur.category !== 'trustee_payment') {
        return Number(acc) + Number(cur.amount)
      }
      return acc
    }, 0)
  }, [expenseItems, trusteePayment]);

  const paidToTrustee = useMemo(() => {
    return expenseItems.reduce((acc, cur) => {
      if (cur.category === 'trustee_payment') {
        return Number(acc) + Number(cur.amount);
      }
      return acc;
    }, 0);
  }, [expenseItems, trusteePayment])

  const toPayOrPaidToTrustee = useMemo(() => {
    return paidToTrustee > trusteePayment.amount ? paidToTrustee : Number(trusteePayment.amount);
  }, [paidToTrustee, trusteePayment])

  const leftToPayTrustee = useMemo(() => {
    return Number(trusteePayment?.amount) - Number(paidToTrustee);
  }, [trusteePayment, paidToTrustee])

  const expenseGoal = useMemo(() => {
    return totalIncomeGoalsForMonth - (totalExpenseItemsForMonth + toPayOrPaidToTrustee);
  }, [totalIncomeGoalsForMonth, totalExpenseItemsForMonth, toPayOrPaidToTrustee])

  useEffect(() => {
    setData(getData);
  }, [expenseGoal, trusteePayment, incomeGoals]);

  const getData = useMemo(() => {
    const data = [];

    data.push({
      value: Number(paidToTrustee),
      label: 'Paid To Trustee',
      color: '#3CB371'
    });
    data.push({
      value: Number(leftToPayTrustee),
      label: 'To Pay Trustee',
      color: '#FFC772'
    });
    data.push({
      value: Number(totalExpenseItemsForMonth),
      label: 'Current Spend',
      color: '#371F76'
    });
    data.push({ value: Number(expenseGoal), label: 'Remaining', color: '#bfbfbf' });
    return data;
  }, [expenseGoal, trusteePayment, incomeGoals]);

  return (
    <Card title={'Goals'}>
      {data && (
        <div className="flex flex-col items-center gap-8 w-full desktop:h-[600px] tablet:h-[600px] mobile:h-[500px]">
          <PieChart
            series={[
              {
                data,
                innerRadius: 85,
                outerRadius: 120
              }
            ]}
            margin={{
              bottom: 65,
              left: 100,
              right: 100
            }}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0
              }
            }}
            height={300}
            >
            <PieCenterLabel position={0.35} text={true}>
              Remaining
            </PieCenterLabel>
            <PieCenterLabel position={0.53} text={false} value={expenseGoal}>
              {formatNumber(expenseGoal.toFixed(2))}
            </PieCenterLabel>
            <PieCenterLabel position={0.7} text={true}>
              of {formatNumber(totalIncomeGoalsForMonth.toFixed(2))}
            </PieCenterLabel>
          </PieChart>
          <div className="flex flex-col justify-center items-center gap-6">
            <div className="flex gap-5 items-center justify-around w-full">
              <span className="text-center">
                <i className="fa-duotone fa-circle-plus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
              </span>
              <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
                Total Income
              </span>
              <span className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
                {formatNumber(totalIncomeGoalsForMonth.toFixed(2))}
              </span>
            </div>
            <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4">
              <span className="text-center">
                <i className="fa-duotone fa-circle-minus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
              </span>
              <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
                Trustee Payment
              </span>
              <span className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
                {formatNumber(toPayOrPaidToTrustee) || formatNumber(0)}
              </span>
            </div>
            <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4">
              <span className="text-center">
                <i className="fa-duotone fa-circle-minus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
              </span>{' '}
              <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
                Current Spend
              </span>
              <span className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
                {formatNumber(totalExpenseItemsForMonth.toFixed(2)) ||
                  formatNumber(Number(0).toFixed(2))}
              </span>
            </div>
            <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4 mb-4">
              <span className="text-center">
                <i className="fa-solid fa-equals fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
              </span>
              <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
                Remaining
              </span>
              <span className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
                {formatNumber(expenseGoal.toFixed(2))}
              </span>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default EquationComponent;
