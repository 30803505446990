import React from 'react';
import { TFSLogoImg } from '@constants/images';

import Footer from '@components/Footer';
import CarouselSlider from '@components/CarouselSlider';

const MainTemplate = ({ body, images, textFooter, textLinkFooter, linkFooter, logo = true }) => {
  return (
    <div className="w-screen min-h-full m-auto bg-teal flex justify-center items-center">
      <div className="w-full m-auto">
        <div className="flex tablet:flex-col-reverse justify-around items-stretch desktop:gap-0 xl:gap-8 min-h-full">
          <div className="block desktop:hidden mt-14">
            <Footer text={textFooter} textLink={textLinkFooter} link={linkFooter} />
          </div>
          <CarouselSlider images={images} />
          <div className="w-full mb-4 desktop:mb-0 desktop:w-3/5 h-full desktop:mr-4 tablet:rounded-br-3xl tablet:rounded-bl-3xl desktop:rounded-3xl bg-white px-6 desktop:px-12 xl:px-44 py-10 desktop:py-8">
            <div className="flex flex-col justify-around items-center xl:gap-8 h-full">
              <div className="flex flex-col justify-between items-center w-full gap-6 desktop:gap-0 h-5/6">
                {logo && (
                  <img
                    src={TFSLogoImg}
                    alt="TFS Logo"
                    className="w-[227px] h-[77px] mt-6 mobile:mb-6 mb-6"
                  />
                )}
                {body}
              </div>
              <div className="hidden desktop:flex h-1/6 m-auto">
                <Footer text={textFooter} textLink={textLinkFooter} link={linkFooter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTemplate;
