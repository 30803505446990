import React, { useState } from 'react';
import FundingRoutes from '@components/Funding/FundingRoutes.jsx';
import { Breadcrumbs } from '@components/Funding';
import Loader from '@components/Loader';
import { EnrollmentContext } from '@contexts';

const EnrollmentFunding = () => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <EnrollmentContext.Provider value={{ enrollment: false }}>
      <div className="flex flex-col items-center justify-around w-full h-full pt-10 pb-7 gap-7 px-4 funding">
        <Breadcrumbs />
        <FundingRoutes
          setLoading={setLoading}
          navTitle="Fund your TFS Bank Account"
          navDescription="We provide multiple ways to add money to your bank account"
        />
      </div>
    </EnrollmentContext.Provider>
  );
};

export default EnrollmentFunding;
