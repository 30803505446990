import React, { useState, useEffect } from 'react';
import GetStarted from './Cards/GetStarted';
import TrackSuccess from './Cards/TrackSuccess';
import Loader from '../Loader';
import { getIndexExpenseGoals, getIndexIncomeGoals } from '@helpers/api/tracker';

const PaymentTracking = () => {
  const [getStarted, setGetStarted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [expenseGoals, setExpenseGoals] = useState();
  const [incomeGoals, setIncomeGoals] = useState();

  const fetchData = async () => {
    try {
      const resultExpenseGoals = await getIndexExpenseGoals();
      const dataExpenseGoals = resultExpenseGoals.data;
      setExpenseGoals(dataExpenseGoals);

      const resultIncomeGoals = await getIndexIncomeGoals();
      const dataIncomeGoals = resultIncomeGoals.data;
      setIncomeGoals(dataIncomeGoals);

      if (
        dataExpenseGoals.expense_goals.length === 0 ||
        !dataExpenseGoals.expense_goals.some((val) => val.category === 'trustee_payment') ||
        !dataExpenseGoals.expense_goals.some((val) => val.category === 'cushion') ||
        dataIncomeGoals.income_goals.length === 0
      ) {
        setGetStarted(true);
      } else {
        setGetStarted(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="tracker-page flex flex-col desktop:px-14 gap-4 mobile:w-screen desktop:w-full tablet:w-full">
      {getStarted && (
        <GetStarted
          setGetStarted={setGetStarted}
          expenseGoals={expenseGoals}
          incomeGoals={incomeGoals}
          setExpenseGoals={setExpenseGoals}
          setIncomeGoals={setIncomeGoals}
        />
      )}
      {!getStarted && <TrackSuccess />}
    </div>
  );
};

export default PaymentTracking;
