import React, { useContext, useEffect, useState } from 'react';
import { TrackSuccessContext } from '@contexts';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, axisClasses } from '@mui/x-charts';
import Card from '../Shared/Card';
import ExpenseItems from './ExpenseItems';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EXPENSE_TYPE } from '@utilities/constants';
import GaugeChart from './GaugeChart';

export default function Expenses() {
  const [chart, setChart] = useState();
  const [expanded, setExpanded] = useState('panel1');
  const [rowsItemsExpenses, setRowsItemsExpenses] = useState([]);
  const [rowsGoalsExpenses, setRowsGoalsExpenses] = useState();
  const [incomeMinusExpenses, setIncomeMinusExpenses] = useState(0);
  const [chartPie, setChartPie] = useState();

  const { expenseItems, expenseGoals } = useContext(TrackSuccessContext);

  const expenseGoalsFilter = (result) => {
    const fixed = result.filter((val) => {
      if (val.fixed && val.category !== 'cushion') {
        return {
          id: val.id,
          amount: val.amount,
          category: val.category
        };
      }
    });
    const variable = result.filter((val) => {
      if (!val.fixed && val.category !== 'cushion') {
        return {
          id: val.id,
          amount: val.amount,
          category: val.category
        };
      }
    });

    const intialValues = {
      fixed_expense: [...fixed],
      variable_expense: [...variable]
    };
    setRowsGoalsExpenses(intialValues);
  };

  useEffect(() => {
    setRowsItemsExpenses(expenseItems);
    expenseGoalsFilter(expenseGoals);
  }, [expenseItems, expenseGoals]);

  useEffect(() => {
    const expenses = groupExpensesItemsByCategory();
    const expensesChart = expenses.map((item, index) => {
      return {
        id: index + 1,
        value: item.current,
        label: item.expense
      };
    });
    const dataPie = [
      { id: 0, value: incomeMinusExpenses, label: 'Income Minus Expenses' },
      ...expensesChart
    ];
    setChartPie(dataPie);
  }, [incomeMinusExpenses]);

  useEffect(() => {
    getExpensesGoal();
  }, [rowsGoalsExpenses]);

  const getExpensesGoal = () => {
    const totalExpenses = rowsGoalsExpenses?.fixed_expense.reduce((acc, obj) => {
      return acc + Number(obj.amount);
    }, 0);
    const totalExpensesVariable = rowsGoalsExpenses?.variable_expense.reduce((acc, obj) => {
      return acc + Number(obj.amount);
    }, 0);
    setIncomeMinusExpenses(
      (Number(totalExpenses) + Number(totalExpensesVariable))
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    createChart(rowsItemsExpenses);
  }, [rowsItemsExpenses, rowsGoalsExpenses]);

  const groupExpensesItemsByCategory = () => {
    const groupedByCategory = rowsItemsExpenses.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = {
          expected: 0,
          current: parseFloat(item.amount),
          expense: EXPENSE_TYPE.find((type) => type.value === item.category)?.label
        };
      } else {
        acc[category].current += parseFloat(item.amount);
      }
      return acc;
    }, {});
    const resultArray = Object.values(groupedByCategory);
    return resultArray;
  };

  const createChart = () => {
    const resultArray = groupExpensesItemsByCategory();
    if (rowsGoalsExpenses) {
      const expensesGoals = [
        ...rowsGoalsExpenses?.fixed_expense,
        ...rowsGoalsExpenses?.variable_expense
      ];
      const groupByCategoryGoals = expensesGoals.reduce((acc, item) => {
        const category = item.category;
        if (!acc[category]) {
          acc[category] = {
            expected: parseFloat(item.amount),
            current: 0,
            expense: EXPENSE_TYPE.find((type) => type.value === item.category)?.label
          };
        } else {
          acc[category].expected += parseFloat(item.amount);
        }
        return acc;
      }, {});
      const resultArrayGoals = Object.values(groupByCategoryGoals);

      const mergedArray = resultArray.map((item1) => {
        const matchingItem = resultArrayGoals.find((item2) => item2.expense === item1.expense);

        if (matchingItem) {
          return {
            expected: item1.expected + matchingItem.expected,
            current: item1.current + matchingItem.current,
            expense: item1.expense
          };
        } else {
          return item1;
        }
      });

      resultArrayGoals.forEach((item2) => {
        const isInMergedArray = mergedArray.some((item) => item.expense === item2.expense);

        if (!isInMergedArray) {
          mergedArray.push(item2);
        }
      });

      setChart(mergedArray);
    } else {
      setChart(resultArray);
    }
  };

  const chartSetting = {
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)'
      }
    },
    slotProps: {
      legend: {
        position: { vertical: 'bottom', horizontal: 'middle' }
      }
    },
    margin: { bottom: 100 }
  };

  return (
    <Card title="Expenses">
      <div className="flex justify-center items-center mb-8">
        {chart && chart.length > 0 && (
          <BarChart
            dataset={chart}
            xAxis={[{ scaleType: 'band', dataKey: 'expense' }]}
            series={[
              { dataKey: 'expected', label: 'Expected', color: '#C15627' },
              { dataKey: 'current', label: 'Current', color: '#247BC7' }
            ]}
            {...chartSetting}
          />
        )}
      </div>
      <Card title="Total Expenses">
        <div className="flex justify-center items-center">
          {chartPie && chartPie.length > 0 && (
            <PieChart
              series={[
                {
                  data: chartPie,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
                }
              ]}
              height={400}
              slotProps={{
                legend: {
                  position: {
                    horizontal: 'middle',
                    vertical: 'bottom'
                  },
                  direction: 'row'
                }
              }}
              margin={{ bottom: 80 }}
            />
          )}
        </div>
      </Card>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold', fontSize: '1.3rem' }}>
            Expenses Items
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExpenseItems />
          <GaugeChart goals={expenseGoals} items={expenseItems} categories={EXPENSE_TYPE} />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
