import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SetPin from '@pages/SetPin';
import Statements from '@pages/Statements.jsx';
import { Settings, RequestNewCard } from '@pages/Settings';
import Funding from '@pages/Funding.jsx';
import Rewards from '@pages/Rewards';
import PrivateRoute from '../../routes/PrivateRoute';
import DashboardMain from './DashboardMain';
import PayBillsPage from '../../pages/PayBillsPage';
import EarlyAchDeposits from '../EarlyAchDeposits';
import { PaymentTracking } from '../Tracker';

const DashboardRoutes = ({ balance, userFeatures }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DashboardMain balance={balance} userFeatures={userFeatures} />
          </PrivateRoute>
        }
      />
      <Route
        path="/set-pin"
        element={
          <PrivateRoute>
            <SetPin />
          </PrivateRoute>
        }
      />
      <Route
        path="/statements"
        element={
          <PrivateRoute>
            <Statements />
          </PrivateRoute>
        }
      />
      {userFeatures.billPay && (
        <Route
          path="/paybills/*"
          element={
            <PrivateRoute>
              <PayBillsPage userFeatures={userFeatures} />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/request-new-card"
        element={
          <PrivateRoute>
            <RequestNewCard />
          </PrivateRoute>
        }
      />
      <Route
        path="/funding/*"
        element={
          <PrivateRoute>
            <Funding />
          </PrivateRoute>
        }
      />
      <Route
        path="/rewards"
        element={
          <PrivateRoute>
            <Rewards />
          </PrivateRoute>
        }
      />
      <Route
        path="/early-ach-deposits"
        element={
          <PrivateRoute>
            <EarlyAchDeposits />
          </PrivateRoute>
        }
      />
      {userFeatures.paymentTracker && (
        <Route
          path="/tracker"
          element={
            <PrivateRoute>
              <PaymentTracking />
            </PrivateRoute>
          }
        />
      )}
    </Routes>
  );
};

export default DashboardRoutes;
