import React, { useState, useEffect } from 'react';
import { DeleteForever, Info } from '@mui/icons-material';
import { Form, Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import Button from '@mui/material/Button';
import { EXPENSE_TYPE } from '../../../utilities/constants';
import { postCreateUpdateExpenseGoals, deleteExpenseGoal } from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';

const validationSchema = Yup.object({
  expense_goals: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .test(
          'maxDigitsAfterDecimal',
          'Monthly amount must be a valid number with a maximum of 2 decimal.',
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .required('This field is required'),
      category: Yup.string().required('This field is required')
    })
  )
});

const ExpenseGoalsComponent = ({
  expenseGoals,
  handleNext,
  handleBack,
  setExpenseGoals,
  fixed,
  headerText,
  incomeGoals
}) => {
  const [expenseGoalsList, setExpenseGoalsList] = useState({
    expense_goals: [
      {
        amount: '',
        category: '',
        fixed: fixed
      }
    ]
  });
  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);

  const expenseGoalsListInitialValues = (values) => {
    const expenseFilter = values.expense_goals.filter(
      (item) =>
        item.category !== 'cushion' && item.category !== 'trustee_payment' && item.fixed === fixed
    );
    if (expenseFilter.length > 0) {
      return expenseFilter;
    } else {
      return [
        {
          amount: '',
          category: '',
          fixed: fixed
        }
      ];
    }
  };

  useEffect(() => {
    if (expenseGoals.expense_goals.length > 0) {
      const resultExpense = expenseGoalsListInitialValues(expenseGoals);
      setExpenseGoalsList({ expense_goals: resultExpense });
    }
  }, [expenseGoals]);

  const removeFromList = async (i, values, setValues) => {
    const expense_goals = [...values.expense_goals];
    let result;
    if (expense_goals[i].id) {
      result = await deleteExpenseGoal(expense_goals[i].id)().catch((e) => console.error(e));
      setExpenseGoals(result.data);
    }
    expense_goals.splice(i, 1);
    if (!fixed) {
      handleEnoughIncome(expense_goals, expenseGoals);
    }
    setValues((prev) => ({ ...prev, expense_goals }));
  };

  const updateForm = (values, setValues) => {
    const expense_goals = [...values.expense_goals];
    expense_goals.push({
      amount: '',
      category: '',
      fixed: fixed
    });
    setValues((prev) => ({ ...prev, expense_goals }));
  };

  const handleSubmit = async (values) => {
    if (fixed === false && handleEnoughIncome(values, expenseGoals)) {
      return false;
    }
    const expense_goals = [...values.expense_goals];
    if (values.expense_goals?.length > 0) {
      try {
        const response = await postCreateUpdateExpenseGoals({
          data: {
            expense_goal: {
              items: expense_goals
            }
          }
        });
        if (response.status === 200) {
          setExpenseGoals(response.data);
          handleNext();
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    else{
      handleNext();
    }
    
  };

  const getIncome = () => {
    return incomeGoals.income_goals.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
  };

  const handleEnoughIncome = (values, expenseGoalsState) => {
    if (!fixed) {
      const variableExpense = values.expense_goals?.reduce(
        (acc, cur) => Number(acc) + Number(cur.amount),
        0
      );

      const fixedExpense = expenseGoalsState.expense_goals
        .filter((val) => val.fixed === true)
        .reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);

      const income = incomeGoals.income_goals.reduce(
        (acc, cur) => Number(acc) + Number(cur.amount),
        0
      );
      setValueEnough(fixedExpense + variableExpense - income);

      setAlert(fixedExpense + variableExpense > income);
      return fixedExpense + variableExpense > income;
    }
    return;
  };

  return (
    <>
      <h3 className="mb-6 text-xl font-semibold">{headerText}</h3>
      {alert && (
        <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
          <p>
            You have entered too many expenses for the month to be able to make your trustee
            payment. Please review your expenses and adjust where necessary to be able to cover your
            trustee payment for the month.
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Income:{' '}
            <CurrencyFormat
              value={getIncome()}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              prefix="$"
            />
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Excess in expenses:{' '}
            <CurrencyFormat
              value={valueEnough}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              prefix="$"
            />
          </p>
        </div>
      )}
      <p className="mb-6 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        This will help us create goals to help you track your monthly payment progress
      </p>
      <Formik
        initialValues={expenseGoalsList}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, setValues, isSubmitting }) => (
          <Form>
            <FieldArray name="expense_goals">
              {() => {
                return values.expense_goals.map((item, i) => (
                  <div key={i} className="flex justify-center items-center gap-8 w-full">
                    <CustomInput
                      name={`expense_goals.${i}.amount`}
                      placeholder="Amount"
                      icon="fa-dollar-sign"
                    />
                    <CustomSelect
                      name={`expense_goals.${i}.category`}
                      placeholder="Expense Type"
                      options={EXPENSE_TYPE.filter(
                        (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                      )}
                    />

                    <button
                      className="cursor-pointer mb-7"
                      type="button"
                      onClick={() => removeFromList(i, values, setValues)}>
                      <DeleteForever />
                    </button>
                  </div>
                ));
              }}
            </FieldArray>
            <button
              className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal mb-8"
              type="button"
              onClick={(e) => updateForm(values, setValues)}>
              + Add additional expense goal
            </button>
            <div>
              <Button
                disabled={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mt: 1, mr: 1 }}>
                Continue
              </Button>

              <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ExpenseGoalsComponent;