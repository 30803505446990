/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ButtonsFormMiddleSteps from '@components/ButtonsFormMiddleSteps';
import SocialSecurityNumberInput from '@components/SocialSecurityNumberInput';
import FormTitle from '@components/FormTitle';
import FormSubtitle from '@components/FormSubtitle';

const id_number = Yup.string()
  .required('You must enter a valid social security number.')
  .length(9, 'You must enter a valid social security number.')
  .nullable();

const id_type = Yup.string().required('Required').nullable();

const VALIDATION_SCHEMA = Yup.object({ id_type, id_number });

const IdentificationForm = ({ accountApplication, setAccountApplication, setCurrentStep }) => {
  const [identification, setIdentification] = useState({
    id_type: '2',
    id_number: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep('identification');
  }, []);

  return (
    <div>
      <FormTitle>Identification</FormTitle>
      <FormSubtitle>
        Providing your social security number allows us to verify the validity of all accounts at
        TFS. We use all best practices when handling sensitive data to make sure your information
        stays secure.
      </FormSubtitle>
      <Formik
        initialValues={identification}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          setAccountApplication({ ...values, ...accountApplication });
          navigate('/account-application/review');
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, errors }) => (
          <Form className="flex flex-col items-center justify-center w-full">
            <Field as="input" type="hidden" name="id_type" />
            <ErrorMessage name="id_type" component="div" />
            <SocialSecurityNumberInput
              errors={errors}
              identification={identification}
              name="id_number"
              setIdentification={setIdentification}
              type="text"
              value={values.id_number}
            />

            <ButtonsFormMiddleSteps
              linkGoBack="/account-application/address"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IdentificationForm;
