import React from 'react';
import { Link } from 'react-router-dom';

const NavigationLink = ({ url, icon, label, isSubmenu, activeMenu, setActiveMenu, isHidden }) => {
  if (!isSubmenu) {
    return (
      <li
        className={`sidenav__list-item ${
          isHidden ? 'desktop:hidden tablet:hidden mobile:block' : ''
        } ${activeMenu === url ? 'active__menu' : ''}`}>
        <Link to={url} onClick={() => setActiveMenu(url)}>
          <i className={icon} /> <span>{label}</span>
        </Link>
      </li>
    );
  }
  return (
    <li className="sidenav__list-item !px-0">
      <Link to={url} onClick={() => setActiveMenu(url)}>
        <i className={icon} />{' '}
        <span className={`${activeMenu === url ? 'active__sub_menu' : ''}`}>{label}</span>
      </Link>
    </li>
  );
};

export default NavigationLink;
