import React from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { getClass } from '@helpers/formik';

const CustomSelect = ({ label, ...props }, ref) => {
  const [field] = useField(props);
  const { errors } = useFormikContext();

  return (
    <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        <ErrorMessage
          name={field.name}
          component="div"
          className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
        />
        <div className="selectdiv">
          <select
            {...field}
            {...props}
            className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
              errors,
              field.name
            )}`}>
            <option value="" disabled>
              {props.placeholder}
            </option>
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <ErrorMessage
          name={field.name}
          component="div"
          className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
        />
      </div>
    </div>
  );
};

export default CustomSelect;
