import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Radio, Paper, TableRow, TableCell } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import FieldDatePickerCustom from '../../DatePickerInput/FieldDatePickerCustom';
import { TableComponent, getComparator, stableSort } from './GenerateTable';
import { DateTime } from 'luxon';
import {
  deleteExpenseItem,
  getIndexExpenseItems,
  postExpenseItem,
  putExpenseItem
} from '@helpers/api/tracker';
import { EXPENSE_TYPE } from '@utilities/constants';
import { TrackSuccessContext } from '@contexts';

export const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'The trust payment must be a valid number with a maximum of 2 decimal.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required'),
  day: Yup.string().required('This field is required'),
  expenseType: Yup.string().required('This field is required'),
  description: Yup.string().nullable()
});

const headCells = [
  {
    id: 'amount',
    label: 'Amount'
  },
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'category',
    label: 'Category'
  },
  {
    id: 'description',
    label: 'Description'
  }
];
const ExpenseItems = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [formValues, setFormValues] = useState({
    amount: '',
    expenseType: '',
    day: '',
    id: '',
    external: true,
    description: ''
  });
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const { setExpenseItems, expenseItems } = useContext(TrackSuccessContext);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getIndexExpenseItems({
        params: {
          end_date: dateRange.endDate,
          start_date: dateRange.startDate
        }
      });
      setExpenseItems(result.data.expense_items);
    };
    if (dateRange.startDate !== null && dateRange.endDate !== null) {
      fetchData().catch((error) => {
        console.error(error);
      });
    }
  }, [dateRange]);

  const minDate = DateTime.local().minus({ years: 7 });

  const visibleRows = useMemo(
    () =>
      stableSort(expenseItems, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, expenseItems]
  );

  const handleRowClick = (row) => {
    if (row.category !== 'trustee_payment') {
      setSelected([row.id]);

      setFormValues({
        amount: row.amount,
        expenseType: row.category,
        day: row.effective_date,
        id: row.id,
        external: true,
        description: row.description
      });
    }
  };

  const handleFormSubmit = async (values, actions) => {
    if (selected.length > 0) {
      const result = await putExpenseItem(values.id)({
        data: {
          expense_item: {
            amount: values.amount,
            category: values.expenseType,
            effective_date: values.day,
            external: values.external,
            description: values.description
          }
        }
      }).catch((error) => {
        console.error(error);
        return;
      });
      setExpenseItems(result.data);
    } else {
      const result = await postExpenseItem({
        data: {
          expense_item: {
            amount: values.amount,
            category: values.expenseType,
            effective_date: values.day,
            external: values.external,
            description: values.description
          }
        }
      }).catch((error) => {
        console.error(error);
        return;
      });
      setExpenseItems(result.data);
    }
    actions.resetForm();
    resetForm();
  };

  const handleDelete = async (id) => {
    const result = await deleteExpenseItem(id)().catch((error) => {
      console.error(error);
      return;
    });
    resetForm();
    setExpenseItems(result.data);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const resetForm = () => {
    setSelected([]);
    setFormValues({
      amount: '',
      expenseType: '',
      day: '',
      description: ''
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableComponent
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          headCells={headCells}
          rows={expenseItems.filter((expense) => expense.external === true)}
          setDateRange={setDateRange}
          minDate={minDate}
          dateRange={dateRange}
          title="Your reported external expenses this month">
          <>
            {visibleRows
              ?.filter((val) => val.external === true)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={() => handleRowClick(row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell padding="checkbox">
                      {row.category !== 'trustee_payment' && (
                        <Radio
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                          name="items"
                          value={row.id}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">$ {row.amount}</TableCell>
                    <TableCell align="right">{row.effective_date}</TableCell>
                    <TableCell align="right">
                      {EXPENSE_TYPE.find((type) => type.value === row.category)?.label}
                    </TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                  </TableRow>
                );
              })}
          </>
        </TableComponent>
      </Paper>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableComponent
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          headCells={headCells}
          rows={expenseItems.filter((expense) => expense.external === false)}
          setDateRange={setDateRange}
          minDate={minDate}
          dateRange={dateRange}
          filter={false}
          title="Your reported expenses generated this month">
          <>
            {visibleRows
              ?.filter((val) => val.external === false)
              .map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell />
                    <TableCell align="right">$ {row.amount}</TableCell>
                    <TableCell align="right">{row.effective_date}</TableCell>
                    <TableCell align="right">
                      {EXPENSE_TYPE.find((type) => type.value === row.category)?.label}
                    </TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                  </TableRow>
                );
              })}
          </>
        </TableComponent>
      </Paper>
      <Paper className="p-8">
        <h2 className="font-bold">
          {selected.length > 0 ? 'Update Expense Source' : 'Additional Expense Source'}
        </h2>
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleFormSubmit}>
          {({ errors, values }) => (
            <Form className="flex flex-col justify-center w-full gap-6 pt-4">
              <div className="flex justify-center items-center gap-4 mobile:flex-col">
                <CustomInput name="amount" placeholder="Amount" icon="fa-dollar-sign" />
                <CustomSelect
                  name="expenseType"
                  placeholder="Expense Type"
                  options={EXPENSE_TYPE.filter(
                    (val) => val.value !== 'trustee_payment' && val.value !== 'cushion'
                  )}
                />
                <CustomInput name="description" placeholder="Description" icon="" />
                <FieldDatePickerCustom name="day" placeholder="Day" errors={errors} />
              </div>
              <div className="flex justify-center items-center gap-4">
                <button
                  className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                  type="submit">
                  {selected.length > 0 ? 'Update' : '+ Add additional expense source'}
                </button>
                {selected.length > 0 && (
                  <>
                    <button
                      className="bg-redDarker text-white px-4 py-2 rounded hover:bg-redDarker"
                      type="button"
                      onClick={() => handleDelete(values.id)}>
                      Delete
                    </button>

                    <button
                      className="bg-greige text-white px-4 py-2 rounded hover:bg-greige"
                      type="button"
                      onClick={() => resetForm()}>
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ExpenseItems;
