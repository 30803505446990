import React, { useContext } from 'react';
import { TFSWhiteImg } from '@constants/images';
import { AccountContext } from '../../../contexts';
import BannerOption from './BannerOption';

const Banner = ({ userFeatures }) => {
  const { account } = useContext(AccountContext);

  return (
    <div className="banner p-6">
      <div className="flex justify-start items-center">
        <div className="flex flex-col justify-start items-center gap-3 w-full">
          <h2 className="text-bannerTitle font-bold">
            {account.current_card?.pin_set_at
              ? 'Quick Actions'
              : 'Complete These Steps To Set Up Your Account'}
          </h2>
          <ul className="flex flex-col w-full gap-3">
            {account.current_card?.shipped && !account.current_card?.pin_set_at && (
              <BannerOption label="Activate your card" link="/dashboard/set-pin" isCardActivation />
            )}
            {account.current_card?.pin_set_at && (
              <BannerOption label="TFS rewards" link="/dashboard/rewards" />
            )}
            <BannerOption label="Fund your account" link="/dashboard/funding" />
            {userFeatures.billPay && (
              <BannerOption label="Organize your bills" link="/dashboard/paybills" />
            )}
          </ul>
        </div>
        <div className="image-steps relative desktop:flex self-stretch tablet:hidden mobile:hidden w-full">
          <img src={TFSWhiteImg} alt="" className="w-[275px] m-auto opacity-50" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
