import React, { useEffect } from 'react';
import { rewards as rewardsApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, useLocation } from 'react-router-dom';
import { swalConfirmCloseConfig } from '@constants/sweetAlert';
import Loader from '@components/Loader';

const swalPopUp = withReactContent(Swal);

const Rewards = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deeplink = queryParams.get('deeplink');

  const getSsoLink = (deeplink) => {
    rewardsApi
      .getSsoLink({ deeplink })
      .then((response) => {
        swalPopUp
          .fire({
            title:
              'You will be redirected to a new page in a new window. Would you like to continue?',
            ...swalConfirmCloseConfig,
            html: `
                  <div className='font-medium text-footerElement text-black'>
                    This optional Cash Back Rewards program is not a Pathward product or service, nor is it endorsed or supported by Pathward in any way.
                  </div>
                  `
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.open(response.data.sso_link);
            }
            navigate(`/dashboard`);
          });
      })
      .catch(() => {
        swalPopUp
          .fire({
            title: 'Oh no! Something went wrong. Please try again later.',
            ...swalConfirmCloseConfig
          })
          .then(() => navigate(`/dashboard`));
      });
  };

  useEffect(() => {
    getSsoLink(deeplink);
  }, [deeplink]);

  return <Loader />;
};

export default Rewards;
