import React, { useState, useEffect, useMemo } from 'react';
import { Box, Paper, TableRow, TableCell } from '@mui/material';
import { TableComponent, getComparator, stableSort } from './GenerateTable';
import { DateTime } from 'luxon';
import { EXPENSE_TYPE } from '@utilities/constants';
import { getIndexExpenseGoals } from '@helpers/api/tracker';

const headCells = [
  {
    id: 'amount',
    label: 'Amount'
  },
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'category',
    label: 'Category'
  }
];

const ExpenseGoals = ({setRows}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const minDate = DateTime.local().minus({ years: 7 });
  const [rows, setRowsExpense] = useState([]);

  const expenseGoalsFilter = (result) => {
    const data = result.data.expense_goals;
    const fixed = data.filter((val) => {
      if (val.fixed && val.category !== 'cushion') {
        return {
          id: val.id,
          amount: val.amount,
          category: val.category
        };
      }
    });
    const variable = data.filter((val) => {
      if (!val.fixed && val.category !== 'cushion') {
        return {
          id: val.id,
          amount: val.amount,
          category: val.category
        };
      }
    });

    const intialValues = {
      fixed_expense: [...fixed],
      variable_expense: [...variable]
    };

    setRows(intialValues);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getIndexExpenseGoals();
      setRowsExpense(result.data.expense_goals);
      expenseGoalsFilter(result);
    };
    fetchData();
  }, []);

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableComponent
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          headCells={headCells}
          rows={rows}
          setDateRange={setDateRange}
          minDate={minDate}
          dateRange={dateRange}
          title="Your reported expenses this month">
          <>
            {visibleRows?.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: 'pointer' }}>
                  <TableCell />
                  <TableCell align="right">$ {row.amount}</TableCell>
                  <TableCell align="right">{row.effective_date}</TableCell>
                  <TableCell align="right">
                    {EXPENSE_TYPE.find((type) => type.value === row.category)?.label}
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        </TableComponent>
      </Paper>
    </Box>
  );
};

export default ExpenseGoals;
