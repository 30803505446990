import React, { useState, useEffect } from 'react';
import { DeleteForever, Info } from '@mui/icons-material';
import { Form, Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import Button from '@mui/material/Button';
import { INCOME_TYPE } from '../../../utilities/constants';
import { postCreateUpdateIncomeGoals, deleteIncomeGoal } from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';

const validationSchema = Yup.object({
  income_goals: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .test(
          'maxDigitsAfterDecimal',
          'Monthly amount must be a valid number with a maximum of 2 decimal.',
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .required('This field is required'),
      category: Yup.string().required('This field is required')
    })
  )
});

const IncomeGoalsBase = ({
  handleNext,
  incomeGoals,
  handleBack,
  setIncomeGoals,
  fixed,
  headingText,
  expenseGoals
}) => {
  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);
  const [incomeGoalsList, setIncomeGoalsList] = useState({
    income_goals: [
      {
        amount: '',
        category: '',
        fixed: fixed
      }
    ]
  });

  useEffect(() => {
    const localIncomeGoals = incomeGoals.income_goals.filter((incomeGoal) => {
      return incomeGoal.fixed === fixed;
    });
    if (localIncomeGoals.length > 0) {
      setIncomeGoalsList({ income_goals: localIncomeGoals });
    }
  }, [incomeGoals]);

  const removeFromList = async (i, values, setValues) => {
    const income_goals = [...values.income_goals];
    let result;
    if (income_goals[i].id) {
      result = await deleteIncomeGoal(income_goals[i].id)().catch((e) => console.error(e));
      setIncomeGoals(result.data);
    }
    income_goals.splice(i, 1);
    if (!fixed) {
      handleEnoughIncome(income_goals, incomeGoals);
    }
    setValues({ income_goals });
  };

  const updateForm = (values, setValues) => {
    const income_goals = [...values.income_goals];
    income_goals.push({
      amount: '',
      category: '',
      fixed: fixed
    });
    setValues({ income_goals });
  };

  const handleSubmit = async (values) => {
    if (fixed == false && handleEnoughIncome(values, incomeGoals)) {
      return false;
    }
    if (values.income_goals?.length > 0) {
      try {
        const response = await postCreateUpdateIncomeGoals({
          data: {
            income_goal: {
              items: values.income_goals
            }
          }
        });
        if (response.status === 200) {
          setIncomeGoals(response.data);
          handleNext();
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    else{
      handleNext();
    }
  };

  const handleEnoughIncome = (values, incomeGoalsState) => {
    if (!fixed) {
      const variableIncome = values.income_goals?.reduce(
        (acc, cur) => Number(acc) + Number(cur.amount),
        0
      );
      const trusteePayment = expenseGoals.expense_goals.find(
        (value) => value.category === 'trustee_payment'
      );
      const fixedIncome = incomeGoalsState.income_goals
        .filter((val) => val.fixed === true)
        .reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
      setValueEnough(trusteePayment.amount - (fixedIncome + variableIncome || 0));
      setAlert(trusteePayment.amount > fixedIncome + variableIncome);
      return trusteePayment.amount > fixedIncome + variableIncome;
    }
    return;
  };

  return (
    <>
      <h3 className="mb-6 text-xl font-semibold">{headingText}</h3>
      {alert && (
        <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
          <p>
            You have not entered enough income to cover your monthly trustee payment. Please verify
            your income and trustee payment amounts.
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Remaining amount to cover:{' '}
            <CurrencyFormat
              value={valueEnough}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              prefix="$"
            />
          </p>
        </div>
      )}
      <p className="mb-6 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        This will help us create goals to help you track your monthly payment progress
      </p>
      <Formik
        initialValues={incomeGoalsList}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values, setValues, isSubmitting }) => (
          <Form>
            <FieldArray name="income_goals">
              {() =>
                values.income_goals.map((item, i) => (
                  <div key={i} className="flex justify-center items-center gap-8 w-full">
                    <CustomInput
                      name={`income_goals.${i}.amount`}
                      placeholder="Amount"
                      icon="fa-dollar-sign"
                    />
                    <CustomSelect
                      name={`income_goals.${i}.category`}
                      placeholder="Income Type"
                      options={INCOME_TYPE}
                    />
                    <button
                      className="cursor-pointer mb-7"
                      type="button"
                      onClick={() => removeFromList(i, values, setValues)}>
                      <DeleteForever />
                    </button>
                  </div>
                ))
              }
            </FieldArray>
            <button
              className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
              type="button"
              onClick={(e) => updateForm(values, setValues)}>
              + Add additional income source
            </button>
            <div>
              <Button
                disabled={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mt: 1, mr: 1 }}>
                Continue
              </Button>

              <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default IncomeGoalsBase;