import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TrackerAvatarImg } from '@constants/images'
import WelcomeComponent from '../GetStartedSteps/WelcomeComponent';
import TrusteePaymentComponent from '../GetStartedSteps/TrusteePaymentComponent';
import IncomeGoalsComponent from '../GetStartedSteps/IncomeGoalsComponent';
import ExpenseGoalsComponent from '../GetStartedSteps/ExpenseGoalsComponent';
import CushionComponent from '../GetStartedSteps/CushionComponent';

const useStyles = makeStyles({
  customLabelStyle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    fontFamily: 'Montserrat'
  }
});

export default function StepperGetStarted({
  expenseGoals,
  incomeGoals,
  setExpenseGoals,
  setIncomeGoals,
  setGetStarted
}) {
  const [activeStep, setActiveStep] = useState(0);
  const clasess = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: 'Welcome',
      description: ``,
      component: <WelcomeComponent handleNext={handleNext} />
    },
    {
      label: 'Trustee Payment',
      description: `Get Started by entering your current monthly trustee payment`,
      component: (
        <TrusteePaymentComponent
          handleNext={handleNext}
          handleBack={handleBack}
          expenseGoals={expenseGoals}
          setExpenseGoals={setExpenseGoals}
        />
      )
    },
    {
      label: 'Fixed Income Goals',
      description: '',
      component: (
        <IncomeGoalsComponent
          handleNext={handleNext}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          setIncomeGoals={setIncomeGoals}
          fixed={true}
          headingText="Enter any income you expect to receive consistently each month"
          expenseGoals={expenseGoals}
        />
      )
    },
    {
      label: 'Variable Income Goals',
      description: '',
      component: (
        <IncomeGoalsComponent
          handleNext={handleNext}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          setIncomeGoals={setIncomeGoals}
          fixed={false}
          headingText="Enter any income you expect to receive this month, but may change in future months"
          expenseGoals={expenseGoals}
        />
      )
    },
    {
      label: 'Fixed Expense Goals',
      description: ``,
      component: (
        <ExpenseGoalsComponent
          expenseGoals={expenseGoals}
          handleNext={handleNext}
          handleBack={handleBack}
          setExpenseGoals={setExpenseGoals}
          fixed={true}
          headerText="Enter all of the expenses you have each month that never change"
          incomeGoals={incomeGoals}
        />
      )
    },
    {
      label: 'Variable Expense Goals',
      description: ``,
      component: (
        <ExpenseGoalsComponent
          expenseGoals={expenseGoals}
          handleBack={handleBack}
          handleNext={handleNext}
          setExpenseGoals={setExpenseGoals}
          fixed={false}
          headerText="Enter each expense you have this month, but may change in future months"
          incomeGoals={incomeGoals}
        />
      )
    },
    {
      label: 'Cushion',
      description: ``,
      component: (
        <CushionComponent
          expenseGoals={expenseGoals}
          handleBack={handleBack}
          setGetStarted={setGetStarted}
          setExpenseGoals={setExpenseGoals}
          incomeGoals={incomeGoals}
        />
      )
    }
  ];

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel classes={{ label: clasess.customLabelStyle }}>
              <span dangerouslySetInnerHTML={{ __html: step.label }} />
            </StepLabel>
            <StepContent>
              {index != 0 && (
                <img
                  src={TrackerAvatarImg}
                  alt="Budgeting Buddy"
                  className="rounded-full"
                  style={{ width: 100, height: 'auto' }}
                />
              )}
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>{step.component}</Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
