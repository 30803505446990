import { get, byId, post, put, remove, getIndexPerPage } from './httpBase';

const urlIncomeGoals = '/api/income_goals';
const urlExpenseGoals = '/api/expense_goals';
const urlExpenseItems = '/api/expense_items';
const urlPaymentTracking = '/api/payment_tracking';

export const getByIdIncomeGoals = byId(urlIncomeGoals);
export const getIndexIncomeGoals = get(urlIncomeGoals);

export const getByIdExpenseGoals = byId(urlExpenseGoals);
export const getIndexExpenseGoals = get(urlExpenseGoals);

export const getByIdExpenseItems = byId(urlExpenseItems);
export const getIndexExpenseItems = get(urlExpenseItems);

export const postIncomeGoalsTrustePayment = post(
  `${urlIncomeGoals}/create_goals_with_trustee_payment`
);
export const postIncomeGoals = post(`${urlIncomeGoals}/create_goals`);
export const deleteIncomeGoal = (id) => remove(`${urlIncomeGoals}/${id}`);
export const putIncomeGoals = post(`${urlIncomeGoals}/update_goals`);
export const postCreateUpdateIncomeGoals = post(`${urlIncomeGoals}/create_update_goals`);

export const postExpenseItem = post(urlExpenseItems);
export const putExpenseItem = (id) => put(`${urlExpenseItems}/${id}`);
export const deleteExpenseItem = (id) => remove(`${urlExpenseItems}/${id}`);
export const postCreateUpdateExpenseGoals = post(`${urlExpenseGoals}/create_update_goals`);

export const deleteExpenseGoal = (id) => remove(`${urlExpenseGoals}/${id}`);
export const postExpenseGoals = post(`${urlExpenseGoals}/create_goals`);
export const putExpenseGoal = post(`${urlExpenseGoals}/update_goals`);
export const postExpenseGoal = post(urlExpenseGoals);
export const updateExpenseGoal = (id) => put(`${urlExpenseGoals}/${id}`);

export const paymentTracking = get(urlPaymentTracking);
export const getTotalExpenseGoal = get(urlPaymentTracking + '/total_expense_goal');
