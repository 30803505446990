/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { TFSWhiteImg } from '@constants/images';
import Notifications from './Cards/Notifications';
import { SessionContext } from '../../contexts';

const Header = ({
  firstName,
  lastName,
  handleClick,
  logOut,
  balance,
  unreadNotificationsCount
}) => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const [menuActive, setMenuActive] = useState(false);
  const { isOpenNotifications, setIsOpenNotifications } = useContext(SessionContext);

  useEffect(() => {
    setPath(location.pathname);
    setMenuActive(false);
  }, [location]);

  const handleClickNotification = (event) => {
    event.preventDefault();
    setIsOpenNotifications(true);
  };

  return (
    <>
      <div className="menu-icon mobile:flex tablet:hidden desktop:hidden mobile:justify-between mobile:items-center mobile:w-full mobile:bg-greyblue mobile:py-5 mobile:px-6">
        <img src={TFSWhiteImg} alt="" className="w-20" />
        <div className="flex justify-center items-center gap-8">
          <button
            type="button"
            onClick={(e) => handleClickNotification(e)}
            className="relative flex items-center justify-center w-[30px] h-[30px] text-[#333] bg-greyblueHover border-0 outline-none rounded-[50%] cursor-pointer active:bg-[#cccccc]">
            <i className={`${unreadNotificationsCount > 0 ? 'fas' : 'far'} fa-bell text-white`} />
            {unreadNotificationsCount > 0 && (
              <span className="absolute top-[-5px] right-[-15px] w-[25px] h-[25px] bg-redDarker text-white flex justify-center items-center rounded-[50%] text-footerElement font-semibold">
                {unreadNotificationsCount}
              </span>
            )}
          </button>
          <i className="fas fa-bars text-white text-[17px]" onClick={() => handleClick()} />
        </div>
      </div>
      <header className="header mobile:hidden tablet:flex desktop:flex desktop:py-[52px] desktop:px-[58px] tablet:py-5 tablet:px-6 gap-1">
        {path === '/dashboard' ? (
          <h1 className="titleDashboard text-dashBoardTopName font-bold">
            Welcome, {firstName} {lastName}
          </h1>
        ) : (
          <div className="flex gap-3 justify-center items-center bg-teal p-4 rounded-xl tablet:w-full">
            <i className="far fa-credit-card text-white fa-lg" />
            <div className="flex justify-start items-center text-white gap-3 text-headerBalance font-bold">
              Balance{' '}
              <span className="bg-white p-1 desktop:w-[216px] tablet:w-auto h-[35px] text-teal text-titleSlide font-normal">
                <NumberFormat
                  value={balance}
                  decimalScale="2"
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale
                />
              </span>
            </div>
          </div>
        )}
        <div className="header__avatar bg-white rounded-xl px-5 py-2 flex gap-6 justify-center items-center tablet:w-full">
          <Link to="/dashboard/settings" aria-label="Account Settings">
            <i className="fa-solid fa-circle-user text-5xl text-greyblue" />
          </Link>
          <div className="flex flex-col">
            <span className="text-dashBoardName font-bold text-black">
              {firstName} {lastName}
            </span>
          </div>

          <button
            type="button"
            onClick={(e) => handleClickNotification(e)}
            className="relative flex items-center justify-center w-[40px] h-[40px] text-[#333] bg-whiteLighter border-0 outline-none rounded-[50%] cursor-pointer active:bg-[#cccccc]">
            <i
              id="notifications"
              className={`${unreadNotificationsCount > 0 ? 'fas' : 'far'} fa-bell`}
            />
            {unreadNotificationsCount > 0 && (
              <span className="absolute top-[-5px] right-[-15px] w-[25px] h-[25px] bg-redDarker text-white flex justify-center items-center rounded-[50%] text-footerElement font-semibold">
                {unreadNotificationsCount}
              </span>
            )}
          </button>

          <div className="flex justify-center items-center gap-8">
            <div className="menuIco cursor-pointer" onClick={() => setMenuActive((prev) => !prev)}>
              <i className="fa-solid fa-chevron-down" />
              <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className="flex flex-col">
                  <li className="sidenav__list-item">
                    <Link to="/dashboard/settings">
                      <i className="fa-light fa-gear" /> <span>Account settings</span>
                    </Link>
                  </li>
                  <li className="sidenav__list-item">
                    <a onClick={() => logOut()}>
                      <i className="fa-light fa-arrow-right-from-bracket" /> <span>Log out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header mobile:flex tablet:hidden desktop:hidden mt-24 p-6">
        {path === '/dashboard' ? (
          <h1 className="text-dashBoardTopName font-bold">
            Welcome, {firstName} {lastName}
          </h1>
        ) : (
          <div className="flex gap-3 justify-center items-center bg-seafoam p-4 rounded-xl">
            <i className="far fa-credit-card text-white fa-lg" />
            <div className="flex justify-start items-center text-white gap-3 text-headerBalance font-bold">
              Balance{' '}
              <span className="bg-white p-1 w-[180px] h-[35px] text-seafoam text-titleSlide font-normal">
                <NumberFormat
                  value={balance}
                  decimalScale="2"
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  fixedDecimalScale
                />
              </span>
            </div>
          </div>
        )}
      </header>
      {isOpenNotifications && (
        <div className="swal2-container swal2-center swal2-backdrop-show">
          <Notifications setIsOpen={setIsOpenNotifications} />
        </div>
      )}
    </>
  );
};

export default Header;
