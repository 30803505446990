export const AUTHORIZED = Symbol('authorized');
export const POSTED = Symbol('posted');
export const ACH_TRANSACTION = Symbol('ach_transaction');
export const ROUTING_NUMBER = 273070278;
export const POSTAL_BOX_REGEX = /\b(P|p)(ost|ostal)?([ .]*(O|o)(ffice)?)?([ .]*(B|b)ox)?\b/;
export const ZIP_CODE_REGEX = /(^\d{5}$|^\d{5}-\d{4}$)/;

export const INCOME_TYPE = [
  {
    label: 'Salary',
    value: 'salary'
  },
  {
    label: 'Rent',
    value: 'rent'
  },
  {
    label: 'Interest and Dividends',
    value: 'interest_and_dividends'
  },
  {
    label: 'Family Support Payment',
    value: 'family_support_payment'
  },
  {
    label: 'Unemployment Compensation',
    value: 'unemployment_compensation'
  },
  {
    label: 'Social Security',
    value: 'social_security'
  },
  {
    label: 'Government Assistance',
    value: 'government_assistance'
  },
  {
    label: 'Pension or Retirement Income',
    value: 'pension_or_retirement_income'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const EXPENSE_TYPE = [
  {
    label: 'Mortgage',
    value: 'mortgage'
  },
  {
    label: 'Car Payment',
    value: 'car_payment'
  },
  {
    label: 'Utilities',
    value: 'utilities'
  },
  {
    label: 'Trustee Payment',
    value: 'trustee_payment'
  },
  {
    label: 'Cushion',
    value: 'cushion'
  }
];
