import React from 'react';
import { Typography, Button, Box, Paper } from '@mui/material';
import { TrackerAvatarImg } from '@constants/images';

const WelcomeComponent = ({ handleNext }) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <img src={TrackerAvatarImg} alt="Budgeting Buddy" className="rounded-full" style={{ width: 300, height: 'auto' }} />
        <Typography variant="h5" gutterBottom mt={2}>
          Let's get started!
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          I'm here to help you plan for a better financial future. Follow me!
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleNext}>
          Get Started
        </Button>
      </Box>
    </Paper>
  );
};

export default WelcomeComponent;
