import React, { useEffect, useState } from 'react';
import { TrackSuccessContext } from '@contexts';
import Expenses from './Expenses';
import { paymentTracking, getTotalExpenseGoal } from '@helpers/api/tracker';
import EquationComponent from './EquationComponent';
import ExpectedIncomeByCategory from './ExpectedIncomeByCategory';

const TrackSuccess = () => {
  const [incomeGoals, setIncomeGoals] = useState([]);
  const [expenseGoals, setExpenseGoals] = useState([]);
  const [expenseItems, setExpenseItems] = useState([]);
  const [trusteePayment, setTrusteePayment] = useState({});
  const [expenseGoal, setExpenseGoal] = useState(0);

  const fetchData = async () => {
    const paymentTrackingData = await paymentTracking();
    setIncomeGoals(paymentTrackingData.data.income_goals);
    setExpenseGoals(paymentTrackingData.data.expense_goals);
    setExpenseItems(paymentTrackingData.data.expense_items);
    setTrusteePayment(paymentTrackingData.data.trustee_payment);
    setExpenseGoal(paymentTrackingData.data.expense_goal);
  };

  const fetchTotalExpenseGoal = async () => {
    const totalExpenseGoal = await getTotalExpenseGoal();
    setExpenseGoal(totalExpenseGoal.data.expense_goal);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTotalExpenseGoal();
  }, [expenseItems]);

  return (
    <TrackSuccessContext.Provider
      value={{
        incomeGoals,
        expenseGoals,
        expenseItems,
        trusteePayment,
        expenseGoal,
        setIncomeGoals,
        setExpenseGoals,
        setExpenseItems,
        setTrusteePayment,
        setExpenseGoal
      }}>
      <div className="flex justify-center items-center gap-4 w-full">
        <div className="flex flex-col gap-4 bg-white desktop:p-8 mobile:p-0 desktop:w-4/5 mobile:w-full tablet:w-full">
          <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center">
            Track Your Success
          </h1>
          <EquationComponent />
          <Expenses />
          <ExpectedIncomeByCategory />
        </div>
      </div>
    </TrackSuccessContext.Provider>
  );
};

export default TrackSuccess;
