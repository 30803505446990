import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { TFSWhiteImg } from '@constants/images';
import { useLocation } from 'react-router-dom';
import { NavigationLink, NavigationLinkTablet } from './NavigationLink';

const Navigation = ({
  firstName,
  lastName,
  city,
  activeElement,
  activeIcons,
  handleCloseMenu,
  handleCloseMenuTablet,
  logOut,
  setActiveActiveIcons,
  setActiveElement,
  userFeatures
}) => {
  const { pathname } = useLocation();

  const [otherTools, setOtherTools] = useState(false);

  const [activeMenu, setActiveMenu] = useState(pathname);

  const [billCenter, setBillCenter] = useState(false);

  useEffect(() => {
    setActiveActiveIcons(false);
    setActiveElement(false);
    setOtherTools(false);
    setBillCenter(false)
    setActiveMenu(pathname);
  }, [pathname]);

  return (
    <aside
      className={`sidenav mobile:pt-4 mobile:px-6 desktop:pt-16 desktop:px-8 ${
        activeElement ? 'active' : ''
      }`}>
      <div
        className="sidenav__close-icon desktop:block tablet:hidden mobile:block"
        onClick={() => handleCloseMenu()}>
        <i className="fas fa-times sidenav__brand-close" />
      </div>
      <div
        className={`sidenav__close-icon desktop:hidden tablet:hidden mobile:hidden ${
          activeIcons ? 'active' : ''
        }`}
        onClick={() => handleCloseMenuTablet()}>
        <i className="fas fa-times sidenav__brand-close" />
      </div>
      <div className={`nav-header flex flex-col ${activeElement ? 'active' : ''}`}>
        <img src={TFSWhiteImg} alt="" className="w-40 desktop:mb-9 tablet:mb-9 mobile:mb-4" />
        <div className="desktop:hidden tablet:hidden mobile:flex flex-col justify-center mb-3">
          <span className="text-dashBoardName font-bold text-white">
            {firstName} {lastName}
          </span>
          <div className="flex justify-start items-center gap-2">
            <i className="fa-solid fa-circle text-seafoam text-[9px]" />{' '}
            <span className="text-[14px] leading-5 font-normal text-white">Active now</span>
          </div>
        </div>
        <div className="text-errorMessageModal font-bold text-white mb-2">
          {DateTime.now().toFormat('MMM dd, yyyy')}
        </div>
        <div className="flex desktop:flex-col tablet:flex-col mobile:flex-row mobile:justify-start mobile:items-center gap-2">
          <div className="flex items-center gap-2">
            <i className="fa-solid fa-location-dot text-[13px] text-white" />
            <div className="text-white text-descriptionSlide font-light">{city}</div>
          </div>
        </div>
      </div>
      <ul className={`sidenav__list ${activeIcons ? 'active' : ''}`}>
        <NavigationLink
          url="/dashboard"
          icon="fa-light fa-table-layout"
          label="Dashboard"
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        <NavigationLink
          url="/dashboard/statements"
          icon="fa-light fa-building-columns"
          label="Statements"
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        {userFeatures.billPay && (
          <NavigationLink
            url="/dashboard/paybills"
            icon="fa-light fa-wallet"
            label="Bill Center"
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )}
        <NavigationLink
          url="/dashboard/funding"
          icon="fa-light fa-credit-card-front"
          label="Fund your TFS Bank Account"
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        <NavigationLink
          url="/dashboard/rewards"
          icon="fa-light fa-sack-dollar"
          label="TFS Rewards"
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        <li
          className={`sidenav__list-item ${
            activeMenu === '/dashboard/settings/request-new-card' ||
            activeMenu === '/dashboard/early-ach-deposits'
              ? 'active__menu'
              : ''
          }`}>
          <div
            onClick={() => setOtherTools((prev) => !prev)}
            onMouseLeave={() => setOtherTools(false)}
            className="flex !flex-col !justify-start !items-start">
            <div className="flex flex-row gap-5 items-center justify-center">
              <i className="fa-light fa-screwdriver-wrench" />{' '}
              <span>Other features and services</span>
            </div>
            <div className={`${otherTools ? 'block' : 'hidden'}`}>
              <ul className="submenu">
                <NavigationLink
                  url="/dashboard/settings/request-new-card"
                  label="Request new card"
                  icon="fa-light fa-credit-card-blank"
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  isSubmenu
                />
                <NavigationLink
                  url="/dashboard/early-ach-deposits"
                  label="Early access to deposits"
                  icon="fa-light fa-money-bill-transfer"
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  isSubmenu
                />
              </ul>
            </div>
          </div>
        </li>
        {userFeatures.paymentTracker && (
          <NavigationLink
            url="/dashboard/tracker"
            icon="fa-light fa-chart-scatter-bubble"
            label="Tracker"
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )}
        <NavigationLink
          url="/dashboard/settings"
          icon="fa-light fa-gear"
          label="Account settings"
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        <li className="sidenav__list-item desktop:hidden tablet:block mobile:block">
          <a onClick={() => logOut()} className="text-link">
            <i className="fa-light fa-arrow-right-from-bracket" /> <span>Log out</span>
          </a>
        </li>
      </ul>
      <div
        className={`sidenav__list-icons ${
          !activeIcons ? 'active' : ''
        } desktop:hidden tablet:flex mobile:hidden flex-col justify-center items-center`}
        onClick={() => handleCloseMenuTablet()}>
        <img src={TFSWhiteImg} alt="" className="w-14 desktop:mb-9 tablet:mb-9 mobile:mb-4" />
        <ul className="sidenav__list active">
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-table-layout"
            url="/dashboard"
          />
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-building-columns"
            url="/dashboard/statements"
          />
          {userFeatures.billPay && (
            <NavigationLinkTablet
              activeMenu={activeMenu}
              icon="fa-light fa-wallet"
              url="/dashboard/paybills"
            />
          )}
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-credit-card-front"
            url="/dashboard/funding"
          />
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-sack-dollar"
            url="/dashboard/rewards"
          />
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-circle-question"
            url="/3"
          />
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-screwdriver-wrench"
            url="/dashboard/settings/request-new-card"
          />
          {userFeatures.paymentTracker && (
            <NavigationLinkTablet
              activeMenu={activeMenu}
              icon="fa-light fa-chart-scatter-bubble"
              url="/dashboard/tracker"
            />
          )}
          <NavigationLinkTablet
            activeMenu={activeMenu}
            icon="fa-light fa-gear"
            url="/dashboard/settings"
          />
          <li className="sidenav__list-item desktop:hidden tablet:hidden mobile:block">
            <i className="fa-light fa-arrow-right-from-bracket" />
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Navigation;
