import React, { useEffect, useState, useContext } from 'react';
import Loader from '@components/Loader';
import { useLocation } from 'react-router-dom';
import { Header, Footer, Navigation, ModalSignOut } from '@components/Dashboard';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SignOut } from '@helpers/SignOut';
import { swalSignOut } from '@constants/sweetAlert';
import { accounts as accountsApi, balance as balanceApi } from '@helpers/api';
import DashboardRoutes from '@components/Dashboard/DashboardRoutes.jsx';
import { AccountContext } from '@contexts';
import { SessionContext } from '@contexts';

const Dashboard = () => {
  const location = useLocation();
  const { account, setAccount } = useContext(AccountContext);
  const [balance, setBalance] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeElement, setActiveElement] = useState(false);
  const [activeIcons, setActiveActiveIcons] = useState(false);
  const swalPopUp = withReactContent(Swal);
  const { user } = useContext(SessionContext);


  const modalSignOut = () => {
    swalPopUp
      .fire({
        html: <ModalSignOut />,
        ...swalSignOut
      })
      .then((event) => {
        if (event.isConfirmed) SignOut();
      });
  };

  useEffect(() => {
    setActiveElement(false);
  }, [location]);

  useEffect(() => {
    accountsApi
      .getShow()
      .then((response) => {
        setAccount(response.data);
        balanceApi
          .getShow()
          .then((responseBalance) => {
            setBalance(responseBalance.data);
            setLoading(false);
          })
          .catch((errorsApi) => {
            setErrors(errorsApi);
            setLoading(false);
          });
      })
      .catch((errorsApi) => {
        setErrors(errorsApi);
        setLoading(false);
      });
  }, []);

  const handleClick = () => {
    setActiveElement((prev) => !prev);
  };

  const handleCloseMenuTablet = () => {
    setActiveActiveIcons((prev) => !prev);
    setActiveElement((prev) => !prev);
  };

  if (loading) return <Loader />;

  return (
    <div className="grid-container">
      {!!errors && <h2>{errors}</h2>}
      <Header
        firstName={account?.first_name}
        lastName={account?.last_name}
        unreadNotificationsCount={account?.unread_notifications_count}
        handleClick={handleClick}
        logOut={modalSignOut}
        balance={balance?.balance}
      />
      <Navigation
        activeElement={activeElement}
        activeIcons={activeIcons}
        firstName={account?.first_name}
        lastName={account?.last_name}
        city={account?.city}
        handleCloseMenuTablet={handleCloseMenuTablet}
        handleCloseMenu={handleClick}
        logOut={modalSignOut}
        setActiveActiveIcons={setActiveActiveIcons}
        setActiveElement={setActiveElement}
        userFeatures={user.features}
      />
      <DashboardRoutes
        balance={balance?.balance}
        userFeatures={user.features} />
      <Footer />
    </div>
  );
};

export default Dashboard;
