import React from 'react';
import { DateTime } from 'luxon';
import {
  DDAAgreementLink,
  PathwardPrivacyPolicy,
  TFSBankAccountESignDisclosure
} from '@constants/pdfs';

const Footer = () => {
  const time = DateTime.now().toFormat('yyyy');

  return (
    <footer className="footer flex flex-col justify-center items-center mt-2">
      <p className="desktop:text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center p-4 w-3/5">
        TFS Bank Account is a demand deposit account established by, and the TFS Debit Card is
        issued by, Pathward, N.A., Member FDIC. Mastercard® and the circles design are registered
        trademarks of Mastercard International Incorporated. Card is only valid for use in the U.S.
      </p>
      <div className="flex mobile:flex-col-reverse tablet:flex-col-reverse desktop:flex-row mobile:gap-4 tablet:gap-2 justify-between items-center w-full">
        <div className="footer__copyright text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet font-normal text-center">
          TFS {time} © All Rights Reserved
        </div>
        <div className="footer__signature">
          <div className="flex mobile:flex-col tablet:flex-row justify-around items-center tablet:gap-8 mobile:gap-4 desktop:gap-8">
            <span className="text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center">
              Customer Service: <a href="tel:866-357-6095">866-357-6095</a>
            </span>
            <a
              href={TFSBankAccountESignDisclosure}
              target="_blank"
              rel="noreferrer"
              className="text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center">
              Electronic Communications Agreement
            </a>
            <a
              href={PathwardPrivacyPolicy}
              target="_blank"
              rel="noreferrer"
              className="text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center">
              Pathward Privacy Policy
            </a>
            <a
              href={DDAAgreementLink}
              target="_blank"
              rel="noreferrer"
              className="text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center">
              Deposit Account Agreement
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
