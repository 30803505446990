import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import CustomInput from '../Inputs/CustomInput';
import { postCreateUpdateExpenseGoals } from '../../../helpers/api/tracker';

const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'Must be a valid number with a maximum of 2 decimals.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required')
});

const initial_values = {
  id: null,
  amount: '',
  category: 'trustee_payment',
  fixed: true
};

const TrusteePaymentComponent = ({ handleNext, handleBack, expenseGoals, setExpenseGoals }) => {
  const [trusteePayment, setTrusteePayment] = useState(initial_values);

  useEffect(() => {
    if (expenseGoals) {
      const trusteePaymentValue = expenseGoals.expense_goals.find(
        (goal) => goal.category === 'trustee_payment'
      );
      if (trusteePaymentValue) {
        setTrusteePayment({ ...trusteePaymentValue });
      }
    }
  }, [expenseGoals]);

  const handleSubmit = async (values) => {
    try {
      const response = await postCreateUpdateExpenseGoals({
        data: {
          expense_goal: {
            items: [...expenseGoals.expense_goals, values]
          }
        }
      });

      if (response.status === 200) {
        setExpenseGoals(response.data);
        handleNext();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={trusteePayment}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form className="my-8">
          <CustomInput
            name="amount"
            placeholder="Trustee Payment Amount"
            icon="fa-dollar-sign"
            title="Enter the amount you are scheduled to pay each month as part of your banckruptcy plan"
          />
          <div>
            <Button disabled={isSubmitting} variant="contained" type="submit" sx={{ mt: 1, mr: 1 }}>
              Continue
            </Button>

            <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              Back
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TrusteePaymentComponent;
