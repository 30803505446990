import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTING_NUMBER } from '@utilities/constants';
import { accounts as accountsApi } from '@helpers/api';
import ErrorsTemplate from '@components/ErrorsTemplate';
import { EnrollmentContext } from '@contexts';
import FormTitle from '@components/FormTitle';
import AchToggle from '@components/AchToggle';

const BankAccount = () => {
  const [hideAccNumber, setHideAccNumber] = useState(true);
  const [account, setAccount] = useState({
    first_name: '',
    last_name: '',
    address: { address_1: '', city: '', state: '', postal_code: '' },
    galileo_payment_reference_number: ''
  });
  const [errors, setErrors] = useState(null);

  const { pathname } = useLocation();
  const lastIndex = pathname.lastIndexOf('/');
  const before = pathname.slice(0, lastIndex);

  const { enrollment } = useContext(EnrollmentContext);

  useEffect(() => {
    accountsApi
      .getShow()
      .then((response) => {
        setAccount({ ...response.data });
      })
      .catch((errorsApi) => {
        setErrors(errorsApi);
      });
  }, []);

  return (
    <>
      {!!errors && <ErrorsTemplate errors={errors} />}
      <div className="flex desktop:flex-row tablet:flex-col desktop:w-1/2 tablet:w-full mobile:w-full gap-14 justify-center items-center">
        <div className="w-full shadow-achShadow rounded-3xl desktop:px-8 tablet:px-6 mobile:px-3 pb-8 pt-10 bg-white">
          <FormTitle>Bank Account</FormTitle>
          <p className="text-center w-4/5 my-8 mx-auto">
            Enter the routing and bank account numbers below into your existing banking application
            to transfer funds to your TFS Bank Account.
          </p>
          <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full flex flex-col gap-5 justify-center items-center">
              <div className="flex flex-col justify-start items-start">
                <div className="text-darkGray text-link">
                  <label htmlFor="reference_number" aria-label="Account Number">
                    Account Number
                  </label>
                  <span className="ml-4">
                    <input
                      type={hideAccNumber ? 'password' : 'text'}
                      className="p-1 w-36"
                      value={account.galileo_payment_reference_number || ''}
                      disabled
                      id="reference_number"
                    />
                  </span>
                  <button
                    type="button"
                    onClick={() => setHideAccNumber(!hideAccNumber)}
                    className="w-14 inline-flex text-center px-2.5 py-1.5 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 ml-4">
                    {hideAccNumber ? 'Show' : 'Hide'}
                  </button>
                </div>
                <div className="text-darkGray text-link">
                  Routing Number: <span className="ml-4">{ROUTING_NUMBER}</span>
                </div>
              </div>
              {!enrollment && <AchToggle />}
              {enrollment && <AchToggle accountEnrollment={account} setAccount={setAccount} />}
            </div>
          </div>
        </div>
      </div>
      <Link to={before} className="block">
        <div className="flex justify-center items-center gap-2 text-teal font-bold">
          <i className="fa-solid fa-arrow-left-long" />
          <span>Back to funding options</span>
        </div>
      </Link>
    </>
  );
};

export default BankAccount;
