import React, { useEffect, useState } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { Paper, Box, Typography } from '@mui/material';

const theme = createTheme({
  palette: {
    green: { main: '#4caf50' },
    yellow: { main: '#FFC772' },
    red: { main: '#FF8372' }
  }
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 20,
  borderRadius: 5
}));

const GaugeChart = ({ items, goals, categories }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const chartArray = items.map((item) => {
      let percentage =
        (item?.amount * 100) / goals.find((goal) => goal.category === item.category)?.amount || 0;
      let color = 'green';
      if (percentage >= 100) {
        percentage = 100;
        color = 'red';
      }
      if (percentage >= 80 && percentage < 100) {
        color = 'yellow';
      }
      return {
        category: categories.find((itemCategory) => itemCategory.value === item.category)?.label,
        current: item?.amount,
        goal: goals.find((goal) => goal.category === item.category)?.amount || 0,
        currentPercentage: percentage,
        color: color,
        external: item?.external
      };
    });
    setChartData(chartArray.sort((a, b) => (a.category > b.category ? 1 : -1)));
  }, [items]);

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          mt: 2,
          p: 2,
          display: 'flex',
          gap: 2,
          flexDirection: 'column'
        }}>
        <Typography
          variant="h6"
          color="text.primary"
          sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Items in Goals Guages
        </Typography>
        {chartData.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              gap: '0.5rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                {data.category} {data.external ? '(External)' : '(Generated)'}
              </Typography>
              <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                ${Number(data.current).toFixed(2)} / ${Number(data.goal).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={data.currentPercentage}
                  color={data.color}
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  data.currentPercentage
                )}%`}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Paper>
    </ThemeProvider>
  );
};

export default GaugeChart;
